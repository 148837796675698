import React from "react"
import { graphql } from "gatsby"
import parse from "html-react-parser"
import { Helmet } from "react-helmet"
import {
  Article,
  ArticleHeader,
  ArticleDate,
  ArticleSection,
} from "../styledComponents/article"

import SEO from "../components/seo"
import LayoutNoStripe from "../components/layoutNoStripe"

const PaymentPage = ({ data, location }) => {
  const pageTitle = data.wpPage.title
  const pageContent = data.wpPage.content

  return (
    <>    
      <Helmet>
        <meta name={`robots`} content={`noindex, nofollow`} />
      </Helmet>
      <LayoutNoStripe displayNavBorder location={location}>
        <Article itemScope itemType="http://schema.org/Article">
          <ArticleHeader>
            <h1 itemProp="headline">{pageTitle}</h1>
            <ArticleDate dateTime={data.wpPage.dateGmt}>{data.wpPage.date}</ArticleDate>
          </ArticleHeader>
          {!!pageContent && (
            <ArticleSection itemProp="articleBody">
              {parse(pageContent)}
            </ArticleSection>
          )}
        </Article>
      </LayoutNoStripe>
    </>
  )
}

export default PaymentPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    wpPage(slug: { eq: "privacy-policy" }) {
      title
      content
      date(formatString: "MMMM DD, YYYY")
      dateGmt
    }
  }
`
